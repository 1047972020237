


















































































import { defineComponent } from "@vue/composition-api";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { IDocSection } from "@monorepo/catalog/src/views/DocTypeView/types/docTypeElement";
import eventBus from "@monorepo/utils/src/eventBus";
import { MODAL_EVENT_BUS_ACTIONS } from "@monorepo/utils/src/eventBus/events/modal";
import { formModalPayload } from "@monorepo/utils/src/eventBus/utils/formModalPayload";
import { showNotification } from "@monorepo/utils/src/eventBus/utils/showNotification";
import { NOTIFICATION_STATUS } from "@monorepo/utils/src/eventBus/types/notification";
import { convertApiTreeItemToUi, getAddBtnObj, getResultSectionIndex } from "@monorepo/catalog/src/views/DocTypeView/utils/convertApiItemToUi";
import Vue from "vue";
import SelectFilter from "@monorepo/uikit/src/components/common/Select/SelectFilter.vue";
import DocumentTreeDocItem from "@monorepo/catalog/src/views/DocTypeView/components/DocumentTreeDocItem.vue";

export default defineComponent({
  name: "DocumentTree",
  components: {
    SelectFilter,
    DocumentTreeDocItem,
  },
  data() {
    return {
      treeOpen: [],
      treeItems: [] as IDocSection[],
      selectedTreeItems: [],
      draggedDocId: "",
      draggedSectionId: "",
      draggedOverSeparatorId: "",
      isNeedSaveIds: {} as Record<string, boolean>,
      isNeedSaveDocIds: {} as Record<string, boolean>,
      isOpenAll: false,
      isLoadingOpenAll: false,
      isLoadingTree: false,
    };
  },
  computed: {
    ...mapGetters("docTypeView", ["infiniteId", "meta"]),
    isDroppableSection() {
      return (item: IDocSection) => {
        return (
          (item.documentKinds?.length && this.draggedDocId && !item.documentKinds.find((doc) => doc.id === this.draggedDocId)) ||
          (item.subsections?.length &&
            this.draggedSectionId &&
            item.id !== this.draggedSectionId &&
            !item.subsections.find((sub) => sub.id === this.draggedSectionId)) ||
          (!item.documentKinds?.length && !item.subsections?.length)
        );
      };
    },
    isNeedSave() {
      return ({ parentId }: { parentId: number | null }, isDocument?: boolean) => {
        if (isDocument) {
          return this.isNeedSaveDocIds[parentId || "root"];
        }
        return this.isNeedSaveIds[parentId || "root"];
      };
    },
    addBtnArray() {
      return ({ isAddSectionBtn, isAddDocumentBtn, parentId }: { isAddSectionBtn?: boolean; isAddDocumentBtn?: boolean; parentId?: string }) => {
        return [
          { isDocument: false, value: isAddSectionBtn },
          { isDocument: true, value: isAddDocumentBtn },
        ].filter((item) => {
          const resultValue = !item.isDocument ? !this.isNeedSaveDocIds[parentId || "root"] : !this.isNeedSaveIds[parentId || "root"];
          return item.value && resultValue;
        });
      };
    },
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapMutations("docTypeView", ["setSelectedIds", "setData"]),
    ...mapActions("docTypeView", [
      "getDocTypeHierarchy",
      "refreshScroll",
      "deleteDocument",
      "transferDocument",
      "transferSection",
      "addNewSection",
      "addNewDocument",
      "updateDocument",
      "updateSection",
      "deleteSection",
    ]),
    async changeIsOpenAll() {
      this.isLoadingOpenAll = true;
      this.isOpenAll = !this.isOpenAll;
      await (this.$refs.tree as any).updateAll(this.isOpenAll);
      this.isLoadingOpenAll = false;
    },
    editNewSection({ parentId }: { parentId: number | null }, isDocument?: boolean) {
      Vue.set(isDocument ? this.isNeedSaveDocIds : this.isNeedSaveIds, parentId || "root", true);
    },
    cancelAddNewSection(item: any, isDocument?: boolean) {
      Vue.delete(isDocument ? this.isNeedSaveDocIds : this.isNeedSaveIds, item.parentId ? item.parentId : "root");
    },
    async saveNewSection(
      {
        parentId,
        name,
        article,
        storageTermValue,
        parent,
      }: { parentId: number | null; name?: string; article?: string; storageTermValue?: string; parent?: IDocSection },
      isDocument?: boolean
    ) {
      let isSave = false;
      if (isDocument) {
        isSave = await this.addNewDocument({
          versionId: Number(this.$route.query.versionId || this.meta?.version?.id) || "",
          name,
          article,
          storageTermValue,
          sectionId: parentId,
          orderIndex: parent?.documentKinds?.length ? Math.max(...parent.documentKinds.map((item) => item.orderIndex)) + 1 : 1,
        });
      } else {
        isSave = await this.addNewSection({
          versionId: Number(this.$route.query.versionId || this.meta?.version?.id) || "",
          name,
          targetSectionId: parentId || null,
          index: getResultSectionIndex(parent || { subsections: this.treeItems }) + 1,
        });
      }
      //Vue.set(isDocument ? this.isNeedSaveDocIds : this.isNeedSaveIds, parentId || "root", false);
      this.refreshScroll();
    },
    onDragStartDoc(id: string) {
      this.draggedSectionId = "";
      this.draggedDocId = id;
    },
    onDragStartSection(id: string) {
      this.draggedDocId = "";
      this.draggedSectionId = id;
    },
    transferToSection(item: IDocSection) {
      if (this.draggedDocId) {
        if ((item.children || []).find((child) => child.id?.toString() === this.draggedDocId.toString())) {
          return;
        }
        this.transferDocToSection(item);
      } else if (this.draggedSectionId) {
        if ((item.children || []).find((child) => child.id?.toString() === this.draggedSectionId.toString())) {
          return;
        }
        this.transferSectionToSection(item);
      }
    },
    transferDocToSection(item: IDocSection) {
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "DeleteModal", {
          cbOnDelete: () => {
            this.moveDocToSection({
              documentKindId: Number(this.draggedDocId),
              sectionId: item.id ? Number(item.id) : null,
              index: item.documentKinds?.length ? (item.documentKinds[item.documentKinds.length - 1]?.orderIndex || 0) + 1 : 1,
            });
          },
          yesBtnTitle: "Да, перенести",
          title: "Перенести документ?",
        })
      );
    },
    transferSectionToSection(item: IDocSection, isTransferBetween?: boolean) {
      if (isTransferBetween) {
        this.draggedOverSeparatorId = "";
      }
      eventBus.$emit(
        MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
        formModalPayload(true, "DeleteModal", {
          cbOnDelete: () => {
            this.moveSectionToSection({
              sectionId: Number(this.draggedSectionId),
              targetSectionId: isTransferBetween ? (item.parentId ? Number(item.parentId) : null) : item.id ? Number(item.id) : null,
              index: (getResultSectionIndex(item) || 0) + 1,
            });
          },
          yesBtnTitle: "Да, перенести",
          title: "Перенести секцию?",
        })
      );
    },
    transferDocToDoc(item: IDocSection) {
      if (this.draggedDocId && item.orderIndex) {
        if (item.id?.toString() === this.draggedDocId.toString()) {
          return;
        }
        eventBus.$emit(
          MODAL_EVENT_BUS_ACTIONS.TOGGLE_MODAL,
          formModalPayload(true, "DeleteModal", {
            cbOnDelete: () => {
              this.moveDocToSection({
                documentKindId: Number(this.draggedDocId),
                sectionId: item.parentId ? Number(item.parentId) : null,
                index: item.orderIndex,
              });
            },
            yesBtnTitle: "Да, перенести",
            title: "Перенести документ?",
          })
        );
      }
    },
    dragOverSeparator(item: IDocSection) {
      this.draggedOverSeparatorId = item.id;
    },
    dragLeaveSeparator() {
      this.draggedOverSeparatorId = "";
    },
    async moveDocToSection(payload: { documentKindId: number; sectionId: number | null; index: number }) {
      const isSave = await this.transferDocument(payload);
      if (!isSave) {
        showNotification("Ошибка переноса документа");
      } else {
        showNotification("Документ успешно перенесен", NOTIFICATION_STATUS.SUCCESS);
        this.refreshScroll();
      }
      this.draggedDocId = "";
      this.draggedSectionId = "";
    },
    async moveSectionToSection(payload: { sectionId: number; targetSectionId: number | null; index: number }) {
      const isSave = await this.transferSection(payload);
      if (!isSave) {
        showNotification("Ошибка переноса секции");
      } else {
        showNotification("Секция успешно перенесена", NOTIFICATION_STATUS.SUCCESS);
        this.refreshScroll();
      }
      this.draggedDocId = "";
      this.draggedSectionId = "";
    },
    async deleteElementCb(id: string, isDocument?: boolean) {
      let isDeleted;
      if (isDocument) {
        isDeleted = await this.deleteDocument({ ids: [id] });
      } else {
        isDeleted = await this.deleteSection(id);
      }
      if (isDeleted) {
        this.refreshScroll();
      }
    },
    async updateElement(item: any, isDocument?: boolean) {
      let isUpdated;
      if (isDocument) {
        isUpdated = await this.updateDocument({
          id: item.id,
          name: item.name,
          article: item.article ? Number(item.article) : null,
          storageTermValue: item.storageTermValue ? { id: item.storageTermValue } : null,
          comment: item.comment,
          storageTermList: (item.storageTermList || []).map((storageItem: any) => ({
            ...storageItem,
            storageTermValue: storageItem.storageTermValue ? { id: storageItem.storageTermValue } : null,
          })),
        });
      } else {
        isUpdated = await this.updateSection({
          id: item.id,
          data: {
            name: item.name || "",
            index: item.orderIndex,
          },
        });
      }
      if (isUpdated) {
        this.refreshScroll();
      }
    },
    getTreeItems() {
      this.isLoadingTree = true;
      const params = {
        type: this.$route.query.type,
        versionId: this.$route.query.versionId,
      };
      this.getDocTypeHierarchy(params)
        .then((result: IDocSection[]) => {
          if (result) {
            this.treeItems = ((result as IDocSection[]) || [])
              .sort((a, b) => a.orderIndex - b.orderIndex)
              .map((item) => convertApiTreeItemToUi(item, true))
              .concat(getAddBtnObj());
          }
        })
        .then(() => {
          this.isNeedSaveDocIds = {};
          this.isNeedSaveIds = {};
          this.isLoadingTree = false;
        });
    },
  },
  watch: {
    infiniteId: {
      immediate: true,
      handler() {
        this.getTreeItems();
      },
    },
  },
});
